@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
    @apply text-gray-600;
  }
  .field {
    @apply border-b;
    @apply placeholder:text-lg;
    @apply placeholder:text-red-800;
    @apply border-gray-700;
    @apply py-2;
    @apply px-3;
    @apply text-gray-900;
    @apply focus:outline-none;
  }

  .field-label {
    @apply uppercase;
    @apply font-bold;
    @apply text-lg;
    @apply text-gray-900;
    @apply mb-2;
  }

  .field-group {
    @apply flex;
    @apply flex-col;
    @apply mb-8;
  }

  .btn {
    @apply block;
    @apply uppercase;
    @apply text-lg;
    @apply py-2;
    @apply px-8;
    @apply rounded-full;
  }

  .btn-main {
    @apply bg-white;
    @apply text-mainRed;
    @apply border-2;
    @apply border-mainRed;
    @apply py-2;
    @apply px-4;
    @apply font-semibold;
  }

  .btn-red {
    @apply bg-mainRed;
    @apply text-white;
  }

  .btn-red:hover {
    @apply bg-red-500;
  }

  .btn-close {
    @apply block;
    @apply text-white;
    @apply text-lg;
    @apply py-1;
    @apply pl-4;
    @apply pr-2;
    @apply rounded;
    @apply bg-mainRed;
  }

  .btn-close:hover {
    @apply bg-red-500;
  }

  .link {
    @apply block;
    @apply no-underline;
    @apply text-sm;
  }

  .link-grey {
    @apply text-gray-800;
  }

  .link-grey:hover {
    @apply text-gray-800;
  }
}

.loader {
  /*margin: 100px auto;*/
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  /* -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0); */
  z-index: 9999;
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #950901,
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.2),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.2),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.2),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.5),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.7),
      1.8em -1.8em 0 0em #950901, 2.5em 0em 0 0em rgba(149, 9, 1, 0.2),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.2),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.2),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.5),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.7), 2.5em 0em 0 0em #950901,
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.2),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.2),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.2),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.5),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.7), 1.75em 1.75em 0 0em #950901,
      0em 2.5em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.2),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.2),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.5),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.7), 0em 2.5em 0 0em #950901,
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.2),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.2),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.2),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.5),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.7), -1.8em 1.8em 0 0em #950901,
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.2),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.2),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.2),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.5),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.7), -2.6em 0em 0 0em #950901,
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.2),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.2),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.2),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.5),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.7), -1.8em -1.8em 0 0em #950901;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #950901,
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.2),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.2),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.2),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.5),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.7),
      1.8em -1.8em 0 0em #950901, 2.5em 0em 0 0em rgba(149, 9, 1, 0.2),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.2),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.2),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.5),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.7), 2.5em 0em 0 0em #950901,
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.2),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.2),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.2),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.5),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.7), 1.75em 1.75em 0 0em #950901,
      0em 2.5em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.2),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.2),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.5),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.7), 0em 2.5em 0 0em #950901,
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.2),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.2),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.2),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.5),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.7), -1.8em 1.8em 0 0em #950901,
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.2),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.2),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.2),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.5),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.7), -2.6em 0em 0 0em #950901,
      -1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(149, 9, 1, 0.2),
      1.8em -1.8em 0 0em rgba(149, 9, 1, 0.2),
      2.5em 0em 0 0em rgba(149, 9, 1, 0.2),
      1.75em 1.75em 0 0em rgba(149, 9, 1, 0.2),
      0em 2.5em 0 0em rgba(149, 9, 1, 0.2),
      -1.8em 1.8em 0 0em rgba(149, 9, 1, 0.5),
      -2.6em 0em 0 0em rgba(149, 9, 1, 0.7), -1.8em -1.8em 0 0em #950901;
  }
}
